import React, {useState, useRef, useEffect} from "react";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Button} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
import {generateID, isMainlyArabicWithEnglish} from "../utils";
import {headers} from "../services/MenaITechService";

const TextEmbeddingTeacher = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, Ask anything about the embedded files.",
      sender: "assistant",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [pdfText, setPdfText] = useState("");
  const [answerSource, setAnswerSource] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      initChat();
    }
  }, [isActive]);

  const initChat = () => {
    setLoading(false);
  };

  const hdlSend = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: textContent,
      sender: "user",
      direction: "outgoing",
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToChatGPT(newMsgs, textContent);
  };

  const sendToChatGPT = async (chatMsgs, msg) => {
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/Teacher/TextEmbedding/chat`, chatMsgs, {
        headers: {...headers, "Content-Type": "application/json"},
      })
      .then((response) => {
        //console.log("response", response);
        setAnswerSource(response.data.source);
        setMessages(response.data.messages);
        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();

    formData.append(`file`, file);
    formData.append(`data`, JSON.stringify(messages));

    setTyping(true);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/Teacher/TextEmbedding/Voice`, formData, {headers})
      .then((response) => {
        setAnswerSource(response.data.source);
        setMessages(response.data.messages);
        setTyping(false);
        setVoiceFile(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  const postFiles = () => {
    if (!files?.length) {
      return;
    }
    setLoading(true);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }

    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/Teacher/TextEmbedding/Upload`, formData, {headers})
      .then((response) => {
        setFiles([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };

  const postText = (textData) => {
    if (!textData?.length) {
      return;
    }
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_NEW_URL}/api/v4/TextEmbedding/text`,
        {text: textData},
        {headers: {...headers, "Content-Type": "application/json"}}
      )
      .then((response) => {
        setFiles([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };

  const handleFileToTextChange = async (files) => {
    setFiles(files);
  };

  return (
    <>
      <MainContainer style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={{
                    ...msg,
                    direction: msg?.sender?.toLowerCase() === "user" ? "outgoing" : "incoming",
                  }}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSend}
            attachButton={false}
            disabled={loading}
          />
        </ChatContainer>
      </MainContainer>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading}
        />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-3">
        <div className="d-flex align-items-center justify-content-center w-100 mt-3">
          <Button
            disabled={loading}
            className="mx-3"
            onClick={() => {
              inputRef.current.click();
            }}>
            Uplaod
          </Button>
          <p>Upload files for text embedding...</p>
        </div>
        {!!files?.length &&
          files?.map((f, i) => {
            return <p key={i}>{f.name}</p>;
          })}
        <Button
          disabled={loading || (!files?.length && !pdfText?.length)}
          onClick={() => (!!pdfText?.length ? postText(pdfText) : !!files?.length ? postFiles() : {})}>
          {loading ? "Embedding..." : "Start Embedding"}
        </Button>
      </div>
      <input
        accept=".pdf,.xls,.xlsx,.doc,.docx,.txt"
        //value={files}
        multiple
        type="file"
        hidden
        ref={inputRef}
        onChange={(e) => {
          let data = [];
          for (let i = 0; i < e.target.files.length; i++) {
            data.push(e.target.files[i]);
          }
          handleFileToTextChange(data);
        }}
      />

      {!!answerSource && (
        <div>
          Source of answer
          <div>
            {answerSource?.split("-----")?.map((row) => {
              return (
                <div>
                  Chunk:----<span dangerouslySetInnerHTML={{__html: row?.replaceAll("\n", "<br>")}}></span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TextEmbeddingTeacher;
