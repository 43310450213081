import React, {useState, useRef, useEffect} from "react";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Button, Tab} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
import {generateID, isMainlyArabicWithEnglish, toTable} from "../utils";
import {getDocument, GlobalWorkerOptions} from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

GlobalWorkerOptions.workerSrc = pdfjsWorker;

const TextEmbedding = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, Ask anything about the embedded files.",
      sender: "assistant",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [pdfText, setPdfText] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      initChat();
    }
  }, [isActive]);

  const initChat = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/NewChat`, null, "application/json")
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...messages, {message: "An error has occurred initializing the chat! Please try again.", sender: "assistant"}]);
      });
  };

  const hdlSend = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: innerHtml,
      sender: "user",
      direction: "outgoing",
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToChatGPT(newMsgs, textContent);
  };

  const sendToChatGPT = async (chatMsgs, msg) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/embedding/chat`, {text: msg}, "application/json")
      .then((response) => {
        //console.log("response", response);
        let newMsg = typeof response.data.data === "object" ? toTable(response.data.data || []) : "";
        newMsg = (newMsg?.length ? newMsg + "\n" : "") + (response.data.summary || "");
        //console.log("newMsg", newMsg);
        setMessages([...chatMsgs, {message: newMsg, sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();

    formData.append(`file`, file);
    setTyping(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/Voice`, formData, {
        headers: {"Content-Type": "multipart/form-data", "Cross-Origin-Embedder-Policy": "require-corp", "Cross-Origin-Opener-Policy": "same-origin"},
      })
      .then((response) => {
        const newMsg = {
          message: response.data.message,
          sender: "user",
          direction: "outgoing",
        };
        const newMsgs = [...messages, newMsg];
        let newMsgData = typeof response.data.data === "object" ? toTable(response.data.data || []) : "";
        newMsgData = (newMsgData?.length ? newMsgData + "\n" : "") + (response.data.summary || "");
        setMessages([...newMsgs, {message: newMsgData, sender: "assistant"}]);
        setTyping(false);
        setVoiceFile(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  const postFiles = () => {
    if (!files?.length) {
      return;
    }
    setLoading(true);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/upload`, formData, "multipart/form-data")
      .then((response) => {
        setFiles([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };

  const postText = (textData) => {
    if (!textData?.length) {
      return;
    }
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/text`, {text: textData}, "application/json")
      .then((response) => {
        setFiles([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
      });
  };

  const handleFileToTextChange = async (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file && file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = () => {
          const pdfBuffer = new Uint8Array(reader.result);
          extractTextFromPdf(pdfBuffer);
        };
        reader.readAsArrayBuffer(file);
      } else {
        alert("Please upload a PDF file.");
      }
    }
  };

  const extractTextFromPdf = async (pdfBuffer) => {
    try {
      console.log("Start Extracting...");
      const pdf = await getDocument({data: pdfBuffer}).promise;
      let fullText = "";

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();

        let linebreakesIndexes = [];
        let lines = [];
        for (let index = 0; index < textContent.items.length; index++) {
          const element = textContent.items[index];
          if (element.hasEOL) {
            linebreakesIndexes.push(index + 1);
          }
        }

        for (let y = 0; y < linebreakesIndexes.length; y++) {
          const prevBreakIndex = y === 0 ? 0 : linebreakesIndexes[y - 1];
          const breakIndex = linebreakesIndexes[y];

          let slicedArray = textContent.items.slice(prevBreakIndex, breakIndex);
          slicedArray.reverse();
          lines.push(slicedArray.map((s) => s?.str?.replaceAll("-", "") || "").join(" "));
        }

        //const texts = textContent.items.map((item) => item.str);

        fullText += lines.join(" ") + "\n";
      }
      console.log(`fullText ...`, {fullText});
      setPdfText((prev) => `${prev || ""} ${fullText}`);
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
    } finally {
      console.log("Finished Extracting");
    }
  };

  return (
    <>
      <MainContainer style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={msg}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSend}
            attachButton={false}
            disabled={loading}
          />
        </ChatContainer>
      </MainContainer>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading}
        />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-3">
        <div className="d-flex align-items-center justify-content-center w-100 mt-3">
          <Button
            disabled={loading}
            className="mx-3"
            onClick={() => {
              inputRef.current.click();
            }}>
            Uplaod
          </Button>
          <p>Upload files for text embedding...</p>
        </div>
        {!!files?.length &&
          files?.map((f, i) => {
            return <p key={i}>{f.name}</p>;
          })}
        <Button
          disabled={loading || (!files?.length && !pdfText?.length)}
          onClick={() => (!!pdfText?.length ? postText(pdfText) : !!files?.length ? postFiles() : {})}>
          {loading ? "Embedding..." : "Start Embedding"}
        </Button>
      </div>
      <input
        accept="application/pdf"
        //value={files}
        multiple
        type="file"
        hidden
        ref={inputRef}
        onChange={(e) => {
          let data = [];
          for (let i = 0; i < e.target.files.length; i++) {
            data.push(e.target.files[i]);
          }
          // console.log(data);
          handleFileToTextChange(data);
          // setFiles(data);
        }}
      />
    </>
  );
};

export default TextEmbedding;
