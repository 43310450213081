export function isMainlyArabicWithEnglish(text) {
  if (!text) {
    return false;
  }
  // Regular expression patterns to match Arabic and English characters
  const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u10E60-\u10E7F\u1EE00-\u1EEFF]/g;
  const englishPattern = /[a-zA-Z]/g;

  // Count the number of Arabic and English characters
  const arabicCount = (text.match(arabicPattern) || []).length;
  const englishCount = (text.match(englishPattern) || []).length;

  const totalCharacters = arabicCount + englishCount;

  // Ensure the majority of the text is Arabic but it contains some English
  return (arabicCount / totalCharacters > 0.6 && englishCount > 0) || (arabicCount > 0 && englishCount === 0);
}

export const toTable = (data) => {
  if (Array.isArray(data)) {
    try {
      const table = document.createElement("table");
      const tableHeader = table.createTHead();
      const row = tableHeader.insertRow();
      for (const key in data[0]) {
        const th = document.createElement("th");
        th.textContent = key;
        th.style = "border:1px solid black;padding:5px;min-width:100px";
        row.appendChild(th);
      }

      const tableBody = table.createTBody();
      data.forEach((object) => {
        const row = tableBody.insertRow();
        for (const key in object) {
          const cell = row.insertCell();
          cell.style = "border:1px solid black;padding:5px;min-width:100px";
          cell.textContent = object[key];
        }
      });
      table.style = "border:1px solid black;";
      return table.outerHTML;
    } catch (error) {
      console.log("Error toTable", {error});
      return "An error has occurred! Please try again.";
    }
  }
  if (typeof data === "object") {
    try {
      const table = document.createElement("table");
      const thead = document.createElement("thead");
      const tbody = document.createElement("tbody");
      const headerRow = document.createElement("tr");

      // Adding headers
      const keyHeader = document.createElement("th");
      keyHeader.textContent = "Key";
      keyHeader.style = "border:1px solid black;padding:5px;min-width:100px";
      headerRow.appendChild(keyHeader);

      const valueHeader = document.createElement("th");
      valueHeader.textContent = "Value";
      valueHeader.style = "border:1px solid black;padding:5px;min-width:100px";
      headerRow.appendChild(valueHeader);

      thead.appendChild(headerRow);
      table.appendChild(thead);

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const row = document.createElement("tr");
          const keyCell = document.createElement("td");
          const valueCell = document.createElement("td");
          keyCell.style = "border:1px solid black;padding:5px;min-width:100px";
          valueCell.style = "border:1px solid black;padding:5px;min-width:100px";
          keyCell.textContent = key;
          valueCell.textContent = data[key];

          row.appendChild(keyCell);
          row.appendChild(valueCell);

          tbody.appendChild(row);
        }
      }

      table.appendChild(tbody);
      return table.outerHTML;
    } catch (error) {
      console.log("Error toTable", {error});
      return "An error has occurred! Please try again.";
    }
  }
};

export function generateID() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const actions = [
  {
    type: null,
    subType: null,
    name: "Salary Slip",
    code: "salary_slip",
    confirmationLevel: "low",
    description: "",
    attributes: [{name: "Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true}],
  },
  {
    type: null,
    subType: null,
    name: "Vacation Balance",
    code: "vacation_balance",
    confirmationLevel: "low",
    description: "",
    attributes: [{name: "Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: "31/12/2023", mandatory: true}],
  },
  {
    type: "leave",
    subType: "Sick Leave",
    name: "Sick Leave",
    code: "leave:1",
    confirmationLevel: "high",
    description: "Sick Leave",
    attributes: [
      {name: "From_Time", dataType: "time", format: "HH:MM", defaultValue: null, mandatory: true},
      {name: "To_Time", dataType: "time", format: "HH:MM", defaultValue: null, mandatory: true},
      {name: "Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "Reason", dataType: "String", format: null, defaultValue: null, mandatory: false},
    ],
  },
  {
    type: "leave",
    subType: "Work Leave ",
    name: "Work Leave ",
    code: "leave:2",
    confirmationLevel: "high",
    description: "Work Leave ",
    attributes: [
      {name: "From_Time", dataType: "time", format: "HH:MM", defaultValue: null, mandatory: true},
      {name: "To_Time", dataType: "time", format: "HH:MM", defaultValue: null, mandatory: true},
      {name: "Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "Reason", dataType: "String", format: null, defaultValue: null, mandatory: false},
    ],
  },
  {
    type: "vacation",
    subType: "sick",
    name: "Sick Vacation",
    code: "vacation:1",
    confirmationLevel: "high",
    description: "Sick Vacation",
    attributes: [
      {name: "From_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "To_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "resumeDate", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "Reason", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Phone", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Place", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Support_documents", dataType: "file", format: null, defaultValue: null, mandatory: true},
      {name: "Delegated_Employee", dataType: "string", format: null, defaultValue: null, mandatory: true},
    ],
  },
  {
    type: "vacation",
    subType: "annual",
    name: "Annual Vacation",
    code: "vacation:2",
    confirmationLevel: "high",
    description: "Annual Vacation",
    attributes: [
      {name: "From_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "To_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "resumeDate", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "Reason", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Phone", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Place", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Support_documents", dataType: "file", format: null, defaultValue: null, mandatory: true},
      {name: "Delegated_Employee", dataType: "string", format: null, defaultValue: null, mandatory: true},
    ],
  },
  {
    type: "vacation",
    subType: "maternity",
    name: "Maternity ",
    code: "vacation:3",
    confirmationLevel: "high",
    description: "Maternity ",
    attributes: [
      {name: "From_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "To_Date", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "resumeDate", dataType: "date", format: "DD/MM/YYYY", defaultValue: null, mandatory: true},
      {name: "Reason", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Phone", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Place", dataType: "String", format: null, defaultValue: null, mandatory: true},
      {name: "Support_documents", dataType: "file", format: null, defaultValue: null, mandatory: true},
      {name: "Delegated_Employee", dataType: "string", format: null, defaultValue: null, mandatory: true},
    ],
  },
];

//test commit
