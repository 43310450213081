import React, {useState, useEffect} from "react";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Form, Tab} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
import {generateID, isMainlyArabicWithEnglish, toTable} from "../utils";
import AudioPlayer from "../components/AudioPlayer";

const NLPToSQLAgent = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, What can i help you?",
      sender: "assistant",
    },
  ]);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null);

  const [enableTextToSpeech, setEnableTextToSpeech] = useState(false);
  const [ttsResult, setTTSResult] = useState(null);

  useEffect(() => {
    const en = JSON.parse(localStorage.getItem("SQLAGENT_TTS_ENABLED") ?? "false");
    setEnableTextToSpeech(en);
  }, []);

  const hdlSendNlp = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: textContent,
      sender: "user",
      direction: "outgoing",
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToChatGPT(newMsgs, textContent);
  };

  const sendToChatGPT = async (chatMsgs, msg) => {
    setLoading(true);
    const formData = new FormData();
    chatMsgs.map((m, i) => {
      formData.append(`messages[${i}][Sender]`, m.sender);
      formData.append(`messages[${i}][Message]`, m.message);
    });
    formData.append(`tts`, enableTextToSpeech);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/NLP/Agent/Text`, formData, "multipart/form-data")
      .then((response) => {
        setMessages(response.data.messages);
        if (!!response?.data?.tts) {
          const audio = new Audio(response?.data?.tts);
          audio.play();
          setTTSResult(response?.data?.tts);
        }
        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();
    messages.map((m, i) => {
      formData.append(`messages[${i}][Sender]`, m.sender);
      formData.append(`messages[${i}][Message]`, m.message);
    });
    formData.append(`file`, file);
    formData.append(`tts`, enableTextToSpeech);
    setTyping(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/NLP/Agent/Voice`, formData, {
        headers: {"Content-Type": "multipart/form-data", "Cross-Origin-Embedder-Policy": "require-corp", "Cross-Origin-Opener-Policy": "same-origin"},
      })
      .then((response) => {
        setMessages(response.data.messages);
        if (!!response?.data?.tts) {
          const audio = new Audio(response?.data?.tts);
          audio.play();
          setTTSResult(response?.data?.tts);
        }
        setTyping(false);
        setVoiceFile(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  return (
    <>
      <MainContainer
        className="flex-column"
        style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <h3 className="px-3 py-2 border-bottom w-100 d-flex align-items-center justify-content-between">
          <span>Call center agent</span>

          <div>
            <small>
              <Form>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Text to Speech"
                  checked={enableTextToSpeech}
                  onChange={(e) => {
                    localStorage.setItem("SQLAGENT_TTS_ENABLED", e.target.checked);
                    setEnableTextToSpeech(e.target.checked);
                  }}
                />
              </Form>
            </small>
          </div>
        </h3>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  //model={msg}
                  model={{
                    ...msg,
                    message: msg.message,
                    direction: msg?.sender === "user" ? "outgoing" : "incoming",
                  }}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSendNlp}
            disabled={loading}
            attachButton={false}
          />
        </ChatContainer>
        <div className="d-flex align-items-center justify-content-center w-100">{!!ttsResult && <AudioPlayer base64Audio={ttsResult} />}</div>
      </MainContainer>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading}
        />
      </div>
    </>
  );
};

export default NLPToSQLAgent;
