import React, {useState, useRef, useEffect} from "react";
import {Tab} from "react-bootstrap";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";

const OCRBill = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, Attach PDF/Image files!.",
      sender: "assistant",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [files, setFiles] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isActive) {
      //initChat();
    }
  }, [isActive]);

  const initChat = () => {
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v2/Assistant/Chat/New`, null, {
        headers: {
          "Content-Type": "application/json",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          "X-API-KEY":
            "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
          "X-COMPANY-CODE": "UNIHANCE",
        },
      })
      .then((response) => {
        const id = response.data;
        setActiveChatId(id);

        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...messages, {message: "An error has occurred initializing the chat! Please try again.", sender: "assistant"}]);
      });
  };

  const handleOcrSend = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: textContent,
      file: files?.[0] || null,
      sender: "user",
      direction: "outgoing",
      files,
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    ocrSendGPT(newMsgs, newMsg);
  };

  const ocrSendGPT = async (chatMsgs, msg) => {
    const formData = new FormData();

    // formData.append(`chatId`, activeChatId);
    // formData.append(`message`, msg.message);
    if (!msg.files && msg.file) {
      formData.append(`file`, msg.file);
    }
    if (!!msg.files) {
      for (let index = 0; index < msg.files.length; index++) {
        formData.append(`files`, msg.files[index]);
      }
    }
    formData.append(
      `data`,
      JSON.stringify({
        messages: chatMsgs,
      })
    );

    setFiles([]);
    if (inputRef?.current?.value) {
      inputRef.current.value = null;
    }
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v2/assistant/chat/2/old`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          "X-API-KEY":
            "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
          "X-COMPANY-CODE": "UNIHANCE",
        },
        responseType: "blob",
      })
      .then((response) => {
        // //let newData = [...messages, {message: response.data.message, sender: "assistant"}];
        // //setMessages(newData);
        // let messages = response.data.context;
        // const lastMessage = messages?.[messages?.length - 1]?.message;
        // let isJson = false;
        // let jsonObj = null;
        // try {
        //   jsonObj = JSON.parse(lastMessage);
        //   if (jsonObj?.["fields"]) {
        //     jsonObj = {...jsonObj["fields"], ...jsonObj};
        //     delete jsonObj["fields"];
        //   }
        //   isJson = true;
        // } catch (error) {
        //   isJson = false;
        // }

        // if (isJson) {
        //   //downloadCSV(jsonObj);

        //   const fileTitle = jsonObj.documentType || "Bill_Extracted_Data" + "_" + Date.now(); // Change this to your desired file title

        //   const csv = convertToCSV(jsonObj);
        //   downloadCSV(csv, fileTitle + ".csv");

        //   //messages.pop();
        //   //messages.push({
        //   //  sender: "assistant",
        //   //  message: "Your csv file is being downloaded.",
        //   //});
        // }
        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary anchor tag to trigger download
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Results.zip"); // Name the download file
        document.body.appendChild(fileLink);

        // Programmatically trigger the download
        fileLink.click();

        // Clean up by removing the temporary link
        fileLink.parentNode.removeChild(fileLink);

        setMessages([...messages, {message: "The results will be downloaded!.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (files?.length) {
      const mainConainer = document.querySelector(".ocr-container");
      const container = mainConainer.querySelector(".cs-chat-container");
      const fileContainer = container.querySelector("#ocrFile");
      if (!!fileContainer) {
        fileContainer.remove();
      }
      const newFileContainer = document.createElement("div");
      newFileContainer.id = "ocrFile";
      newFileContainer.style.display = "flex";
      newFileContainer.style.alignItems = "center";
      newFileContainer.style.justifyContent = "flex-start";
      newFileContainer.innerHTML = `<span style="display: flex;align-items: center;justify-content: flex-start;" ><svg style="margin:10px"xmlns=" http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="25" height="25"> <path d="M20.1,5.39l-3.49-3.49c-1.23-1.23-2.86-1.9-4.6-1.9H6.5C4.02,0,2,2.02,2,4.5v15c0,2.48,2.02,4.5,4.5,4.5h11c2.48,0,4.5-2.02,4.5-4.5V9.99c0-1.74-.68-3.37-1.9-4.6Zm-.71,.71c.55,.55,.97,1.2,1.24,1.9h-5.13c-.83,0-1.5-.67-1.5-1.5V1.37c.71,.27,1.35,.69,1.9,1.24l3.49,3.49Zm1.61,13.4c0,1.93-1.57,3.5-3.5,3.5H6.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h5.51c.33,0,.66,.03,.99,.09V6.5c0,1.38,1.12,2.5,2.5,2.5h5.41c.06,.32,.09,.65,.09,.99v9.51ZM6.97,13h-.97c-.55,0-1,.45-1,1v4.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.5h.97c1.11,0,2.01-.92,2.01-2.02s-.9-1.98-2.01-1.98Zm0,3h-.97v-2h.97c.56,0,1.01,.44,1.01,.98s-.46,1.02-1.01,1.02Zm5.03-3h0s-.51,0-1,0c-.55,0-1,.45-1,1v4c0,.55,.45,1,1,1,.48,0,.97,0,1,0h0c1.11,0,2-.89,2-1.98v-2.03c0-1.09-.89-1.98-2-1.98Zm1,4.02c0,.53-.43,.96-.96,.98h-1.04v-4h1.04c.53,.02,.96,.46,.96,.98v2.03Zm6-3.52c0,.28-.22,.5-.5,.5h-2.5v2s1.5,0,1.5,0c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5h-1.5v1.5c0,.28-.22,.5-.5,.5s-.5-.22-.5-.5v-4.5c0-.55,.45-1,1-1h2.5c.28,0,.5,.22,.5,.5Z" /></svg><p style="margin:0">${files[0]?.name}</p></span>`;
      //console.log("newFileContainer", {container, newFileContainer});
      container.appendChild(newFileContainer);
    } else {
      if (document.getElementById("ocrFile")) {
        document.getElementById("ocrFile").remove();
      }
    }
  }, [files]);

  // const downloadCSV = (jsonObject) => {
  //   // Parse the JSON string
  //   console.log("downloadCSV", {jsonObject});
  //   // Convert JSON object to CSV
  //   const header = Object.keys(jsonObject).join(",");
  //   const csvRow = Object.values(jsonObject).join(",");
  //   const csvString = [header, csvRow].join("\n");
  //   console.log("downloadCSV", {jsonObject, header, csvRow, csvString});
  //   // Create a Blob from the CSV String
  //   const blob = new Blob([csvString], {type: "text/csv"});

  //   // Create a link to download the Blob
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "data.csv";
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };

  const convertToCSV = (obj) => {
    const flattened = flattenObject(obj);
    const headers = Object.keys(flattened);
    const rows = headers.map((header) => flattened[header]);

    let csvStr = headers.join(",") + "\n" + rows.join(",");

    return csvStr;
  };

  function downloadCSV(csv, filename) {
    let csvFile;
    let downloadLink;

    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const flattenObject = (obj, parentPrefix = "") => {
    let flattened = {};

    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) continue;

      let propName = parentPrefix ? parentPrefix + "_" + key : key;

      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key], propName));
      } else {
        flattened[propName] = `"${obj[key]}"`; // Enclose each value in double quotes
      }
    }

    return flattened;
  };

  return (
    <>
      <MainContainer
        className="ocr-container"
        style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={{
                    ...msg,
                    message: `${msg.message}`,
                    direction: msg?.sender === "user" ? "outgoing" : "incoming",
                  }}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: "left"}}
                />
              );
            })}
          </MessageList>

          <MessageInput
            placeholder="Type message here"
            onSend={handleOcrSend}
            disabled={loading}
            onAttachClick={() => inputRef.current.click()}
          />
        </ChatContainer>
      </MainContainer>
      <input
        accept="application/pdf,image/*"
        multiple
        type="file"
        hidden
        ref={inputRef}
        onChange={(e) => {
          let data = [];
          for (let i = 0; i < e.target.files.length; i++) {
            data.push(e.target.files[i]);
          }
          setFiles(data);
        }}
      />
    </>
  );
};

export default OCRBill;
