import React, {useState, useEffect} from "react";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Tab} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
import {generateID, isMainlyArabicWithEnglish, toTable} from "../utils";

const NLPToSQL = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, Try to query anything from SQL database",
      sender: "assistant",
    },
  ]);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null);

  useEffect(() => {
    if (isActive) {
      initChat();
    }
  }, [isActive]);

  const initChat = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/TextEmbedding/NewChat`, null, "application/json")
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...messages, {message: "An error has occurred initializing the chat! Please try again.", sender: "assistant"}]);
      });
  };

  const hdlSendNlp = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: innerHtml,
      sender: "user",
      direction: "outgoing",
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToChatGPT(newMsgs, textContent);
  };

  const sendToChatGPT = async (chatMsgs, msg) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/NLP/Text`, {text: msg}, "application/json")
      .then((response) => {
        let newMsg = typeof response?.data.data === "object" ? toTable(response?.data.data || []) : "";
        newMsg = (newMsg?.length ? newMsg + "\n" : "") + (response.data.summary || "");
        setMessages([...chatMsgs, {message: newMsg, sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();

    formData.append(`file`, file);
    setTyping(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/NLP/Voice`, formData, {
        headers: {"Content-Type": "multipart/form-data", "Cross-Origin-Embedder-Policy": "require-corp", "Cross-Origin-Opener-Policy": "same-origin"},
      })
      .then((response) => {
        const newMsg = {
          message: response.data.message,
          sender: "user",
          direction: "outgoing",
        };
        const newMsgs = [...messages, newMsg];
        let newMsgData = typeof response.data.data === "object" ? toTable(response.data.data || []) : "";

        newMsgData = (newMsgData?.length ? newMsgData + "\n" : "") + (response.data.summary || "");
        setMessages([...newMsgs, {message: newMsgData, sender: "assistant"}]);
        setTyping(false);
        setVoiceFile(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  return (
    <>
      <MainContainer style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={msg}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSendNlp}
            disabled={loading}
            attachButton={false}
          />
        </ChatContainer>
      </MainContainer>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading}
        />
      </div>
    </>
  );
};

export default NLPToSQL;
