import React, {useState, useRef, useEffect} from "react";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Button} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
import {generateID, isMainlyArabicWithEnglish} from "../utils";
import {headers} from "../services/MenaITechService";
import AudioPlayer from "../components/AudioPlayer";

const AntiCorruptionAssistant = ({isActive}) => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, Ask anything about the embedded files.",
      sender: "assistant",
    },
  ]);
  const [chatId, setChatId] = useState(null);
  const [assistantId, setAssistantId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null);
  const [ttsResult, setTTSResult] = useState(null);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        initChat();
      }, 0);
    }
  }, [isActive]);

  const initChat = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/TeacherAssistant/Chat/New?assistantId=asst_bbRfFSWsGSRgrPQxTGTi2L3F`, null, {
        headers: {...headers, "Content-Type": "application/json"},
      })
      .then((response) => {
        setChatId(response.data?.id);
        setAssistantId(response.data?.assistantId);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...messages, {message: "An error has occurred initializing the chat! Please try again.", sender: "assistant"}]);
      });
  };

  const hdlSend = async (innerHtml, textContent, innerText, nodes, passedChatId, passedAssistantId, passedMessages) => {
    let oldMessages = passedMessages || messages || [];
    const newMsg = {
      message: textContent,
      sender: "user",
      chatId: passedChatId || chatId,
      assistantId: passedAssistantId || assistantId,
      direction: "outgoing",
    };
    const newMsgs = [...oldMessages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToChatGPT(newMsgs, textContent, newMsg);
  };

  const sendToChatGPT = async (chatMsgs, msg, newMsg) => {
    const formData = new FormData();

    formData.append(`chatId`, newMsg?.chatId);
    formData.append(`assistantId`, newMsg?.assistantId);
    formData.append(`message`, newMsg?.message);

    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/TeacherAssistant/Chat`, formData, {headers: headers})
      .then(async (response) => {
        const tts = await textToSpeechAsync(extractTextFromString(response.data?.messages[1].message));
        console.log("tts", {tts});
        setMessages([...messages, ...response.data?.messages]);
        setTTSResult(tts);
        setTyping(false);
        setLoading(false);
        const messageList = document.querySelector("#uncontrolled-tab-example-tabpane-teacherAssistant").querySelector(".cs-message-list");
        if (messageList) {
          messageList.scrollTop = messageList.scrollHeight;
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();

    formData.append(`audioFile`, file);
    formData.append(`chatId`, chatId);
    formData.append(`assistantId`, assistantId);
    formData.append(`message`, JSON.stringify(messages));

    setTyping(true);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v4/TeacherAssistant/Chat`, formData, {headers: headers})
      .then(async (response) => {
        const tts = await textToSpeechAsync(extractTextFromString(response.data?.messages[1].message));
        console.log("tts", {tts});
        setMessages([...messages, ...response.data?.messages]);
        setTTSResult(tts);
        setTyping(false);
        setVoiceFile(null);
        setLoading(false);
        const messageList = document.querySelector("#uncontrolled-tab-example-tabpane-teacherAssistant").querySelector(".cs-message-list");
        if (messageList) {
          messageList.scrollTop = messageList.scrollHeight;
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  const handleSelectOption = (textContent) => {
    hdlSend(null, textContent, null, null, chatId, assistantId, messages);
  };

  useEffect(() => {
    if (chatId && assistantId && messages?.length) {
      window.handleAssistantOptionSelection = handleSelectOption;
    }
  }, [assistantId, chatId, messages]);

  function extractTextFromString(str) {
    // Create a temporary element to hold the string as HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = str;

    // Extract the text from the temporary element
    let extractedText = tempElement.textContent || tempElement.innerText;

    // Remove the temporary element
    tempElement = null;

    return extractedText;
  }

  const textToSpeechAsync = async (text) => {
    try {
      const url = "https://api.elevenlabs.io/v1/text-to-speech/tlETan7Okc4pzjD0z62P";
      const requestData = {
        text: text,
        model_id: "eleven_multilingual_v2",
        voice_settings: {
          stability: 0.5,
          similarity_boost: 1.0,
          style: 0.5,
          use_speaker_boost: true,
        },
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "xi-api-key": "e3b6bf0336b54efa96ce1fc720600fc6",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.log(`API call failed: ${response.status}`);
        return null;
      }

      const audioBlob = await response.blob();
      const audioArrayBuffer = await audioBlob.arrayBuffer();
      return URL.createObjectURL(new Blob([new Uint8Array(audioArrayBuffer)]));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MainContainer style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8, textAlign: "right"}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={{
                    ...msg,
                    direction: msg?.sender?.toLowerCase() === "user" ? "outgoing" : "incoming",
                  }}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSend}
            attachButton={false}
            disabled={loading}
            id="assistant-input"
          />
        </ChatContainer>
      </MainContainer>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center w-100">{!!ttsResult && <AudioPlayer base64Audio={ttsResult} />}</div>
    </>
  );
};

export default AntiCorruptionAssistant;
