import React, {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";

import "./chat.css";
import "./App.css";

import NLPToSQL from "./Tabs/NLPToSQL";
import TextEmbedding from "./Tabs/TextEmbedding";
import Assistant4 from "./Tabs/Assistant4";
import Assistant3 from "./Tabs/Assistant3";
import OCRBill from "./Tabs/OCRBill";
import Assistant1106 from "./Tabs/Assistant1106";
import OCRCV from "./Tabs/OCRCV";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEmbeddingRawData from "./Tabs/TextEmbeddingRawData";
import TextEmbeddingRawData1 from "./Tabs/TextEmbeddingRawData1";
import NLPToSQLAgent from "./Tabs/NLPToSQLAgent";
import TextEmbeddingTeacher from "./Tabs/TextEmbeddingTeacher";
import TeacherAssistant from "./Tabs/TeacherAssistant";
import AntiCorruptionAssistant from "./Tabs/AntiCorruptionAssistant";
import RoyalGuard from "./Tabs/RoyalGuard";

export default function App() {
  const [active, setActive] = useState("royalGuard");
  return (
    <div
      style={{
        padding: 16,
        height: "95vh",
        display: "flex",
        flexFlow: "column",
      }}>
      <Tabs
        activeKey={active}
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={(data) => setActive(data)}>
        <Tab
          eventKey="nlp"
          id="nlp"
          title="NLP to SQL">
          <NLPToSQL isActive={active === "nlp"} />
        </Tab>
        <Tab
          eventKey="textEmbedding"
          id="textEmbedding"
          title="Text Embedding">
          <TextEmbedding isActive={active === "textEmbedding"} />
        </Tab>

        <Tab
          eventKey="assistant3"
          id="assistant3"
          title="Assistant GPT3.5">
          <Assistant3 isActive={active === "assistant3"} />
        </Tab>
        <Tab
          eventKey="assistant4"
          id="assistant4"
          title="Assistant GPT4">
          <Assistant4 isActive={active === "assistant4"} />
        </Tab>
        <Tab
          eventKey="assistant1106"
          id="assistant1106"
          title="Assistant GPT4 1106">
          <Assistant1106 isActive={active === "assistant1106"} />
        </Tab>

        <Tab
          eventKey="ocrcv"
          id="ocrcv"
          title="CV Parsing">
          <OCRCV isActive={active === "ocrcv"} />
        </Tab>
        <Tab
          eventKey="fullChatbot"
          id="fullChatbot"
          title="Text To Action">
          <Assistant1106 isActive={active === "fullChatbot"} />
        </Tab>
        <Tab
          eventKey="textEmbedding1"
          id="textEmbedding1"
          title="MISA (With Creativity)">
          <TextEmbeddingRawData isActive={active === "textEmbedding1"} />
        </Tab>
        <Tab
          eventKey="textEmbedding11"
          id="textEmbedding11"
          title="MISA (With Performance)">
          <TextEmbeddingRawData1 isActive={active === "textEmbedding11"} />
        </Tab>
        <Tab
          eventKey="ocr"
          id="ocr"
          title="Bills Documents Parsing">
          <OCRBill isActive={active === "ocr"} />
        </Tab>
        <Tab
          eventKey="nlpAgent"
          id="nlpAgent"
          title="Call Center Agent">
          <NLPToSQLAgent isActive={active === "nlpAgent"} />
        </Tab>
        <Tab
          eventKey="textEmbeddingTeacher"
          id="textEmbeddingTeacher"
          title="Teacher Assistant Text Embedding">
          <TextEmbeddingTeacher isActive={active === "textEmbeddingTeacher"} />
        </Tab>
        <Tab
          eventKey="teacherAssistant"
          id="teacherAssistant"
          title="Teacher Assistant">
          <TeacherAssistant isActive={active === "teacherAssistant"} />
        </Tab>
        <Tab
          eventKey="antiCorruptionAssistant"
          id="antiCorruptionAssistant"
          title="Anti-Corruption Assistant">
          <AntiCorruptionAssistant isActive={active === "antiCorruptionAssistant"} />
        </Tab>
        <Tab
          eventKey="royalGuard"
          id="royalGuard"
          title="Royal Guard Assistant">
          <RoyalGuard isActive={active === "royalGuard"} />
        </Tab>
        {/* <Tab
          eventKey="NLPAssistant"
          id="NLPAssistant"
          title="NLP To SQL Reporting">
          <AssistantNLPToSQL isActive={active === "NLPAssistant"} />
        </Tab> */}
      </Tabs>
      <ToastContainer />
    </div>
  );
}
