import React, {useEffect, useRef, useState} from "react";

function AudioPlayer({base64Audio}) {
  const ref = useRef();
  const [audioSrc, setAudioSrc] = useState("");

  useEffect(() => {
    if (!!base64Audio) {
      handleAudioLoad();
    }
  }, [base64Audio]);

  const handleAudioLoad = () => {
    setAudioSrc(base64Audio);
  };

  useEffect(() => {
    if (ref.current && audioSrc) {
      ref.current.pause(); // Ensure the current audio stops if a new one is loaded
      ref.current.load();
      ref.current.play();
    }
  }, [audioSrc]);

  return (
    <div className="mt-2">
      <audio
        src={audioSrc}
        controls
        ref={ref}
      />
    </div>
  );
}

export default AudioPlayer;
