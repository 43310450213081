import React, {useState, useEffect, useRef} from "react";
import {renderToString} from "react-dom/server";
import {MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import {Button, Col, Form, FormGroup, FormLabel, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Tab} from "react-bootstrap";
import {AudioRecorder} from "react-audio-voice-recorder";
//import {generateID, isMainlyArabicWithEnglish, actions} from "../utils";
import {generateID, isMainlyArabicWithEnglish} from "../utils";
import MenaITechService from "../services/MenaITechService";
import moment from "moment";
import {toast} from "react-toastify";
import ReactSelect from "react-select";
import AudioPlayer from "../components/AudioPlayer";

const Assistant1106 = ({isActive}) => {
  const supportingDocRef = useRef();
  const ttsRef = useRef();
  const [actions, setActions] = useState([]);
  const [messages, setMessages] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [requestPayload, setRequestPayload] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [feedbackError, setFeedbackError] = useState(null);
  const [feedbackresponse, setFeedbackresponse] = useState(null);

  const [formData, setFormData] = useState({
    companyNumber: "menait",
    branchNumber: "d1",
    employeeCode: "1296",
    password: "1",
  });

  const [loginError, setLoginError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [showDelegateToSelection, setShowDelegateToSelection] = useState(false);
  const [delegateToList, setDelegateToList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [additionalDataError, setAdditionalDataError] = useState(false);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [confirmingAction, setConfirmingAction] = useState(false);
  const [actionParsedObject, setActionParsedObject] = useState(null);

  const [enableTextToSpeech, setEnableTextToSpeech] = useState(false);
  const [ttsResult, setTTSResult] = useState(null);

  useEffect(() => {
    const en = JSON.parse(localStorage.getItem("TTS_ENABLED") ?? "false");
    setEnableTextToSpeech(en);
  }, []);

  useEffect(() => {
    if (isActive) {
      initChat();
    }
  }, [isActive]);

  const initChat = () => {
    setLoading(true);
    setMessages([
      {
        message: "Initializing...",
        sender: "assistant",
      },
    ]);
    MenaITechService.getActions()
      .then((response) => {
        setActions(response.data);
        setMessages([
          {
            message: "Hello, Ask me anything!.",
            sender: "assistant",
          },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let errText = error?.response?.data?.errors?.[0];
        if (errText?.includes("Expired token")) {
          toast.error("Token is expired, Please Login..");
        } else {
          toast.error("An error occurred while initializing the assistant");
        }
        console.log("error getting actions", {error});
      });
  };

  const hdlSend = async (innerHtml, textContent, innerText, nodes) => {
    const newMsg = {
      message: textContent,
      sender: "user",
      direction: "outgoing",
    };
    const newMsgs = [...messages, newMsg];
    setMessages(newMsgs);
    setTyping(true);
    sendToGPT(newMsgs, textContent);
  };

  const sendToGPT = async (chatMsgs, msg) => {
    const formData = new FormData();
    formData.append(
      `data`,
      JSON.stringify({
        messages: chatMsgs,
        actions,
        token: MenaITechService.getToken(),
        tts: enableTextToSpeech,
      })
    );
    setRequestPayload(null);
    setActionParsedObject(null);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v1/assistant/chat`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          "X-API-KEY":
            "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
          "X-COMPANY-CODE": "UNIHANCE",
        },
      })
      .then((response) => {
        let context = response.data.context;
        if (
          response?.data?.actionResult &&
          (response?.data?.actionCode?.toLowerCase() === "salary_slip" || response?.data?.actionCode?.toLowerCase() === "vacation_balance")
        ) {
          const action = JSON.parse(response?.data?.actionResult);
          const actionMsg = action?.msg;
          if (actionMsg?.includes("Has Not Been Released")) {
            context.pop();
            context.push({
              sender: "assistant",
              message: actionMsg,
            });
          }
          if (actionMsg?.includes("No Records Found")) {
            context.pop();
            context.push({
              sender: "assistant",
              message: actionMsg,
            });
          }
        }

        setMessages([...response.data.context]);
        if (response?.data?.actionResult) {
          setRequestPayload({
            actionResponse: response?.data?.actionResponse,
            actionResult: response?.data?.actionResult,
            actionCode: response?.data?.actionCode,
          });
          setActionParsedObject(JSON.parse(response?.data?.actionResult));
        }

        if (!!response?.data?.tts) {
          const audio = new Audio(response?.data?.tts);
          audio.play();
          setTTSResult(response?.data?.tts);
        }

        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setMessages([...chatMsgs, {message: "An error has occurred! Please try again.", sender: "assistant"}]);
        setTyping(false);
        setLoading(false);
      });
  };

  const addAudioElement = (blob) => {
    if (loading) {
      return;
    }
    var file = new File([blob], `${generateID()}.webm`);

    const formData = new FormData();

    formData.append(`voice`, file);
    formData.append(
      `data`,
      JSON.stringify({
        messages: messages,
        actions,
        token: MenaITechService.getToken(),
        tts: enableTextToSpeech,
      })
    );

    setTyping(true);
    setRequestPayload(null);
    setActionParsedObject(null);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v1/assistant/chat`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          "X-API-KEY":
            "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
          "X-COMPANY-CODE": "UNIHANCE",
        },
      })
      .then((response) => {
        let context = response.data.context;
        if (
          response?.data?.actionResult &&
          (response?.data?.actionCode?.toLowerCase() === "salary_slip" || response?.data?.actionCode?.toLowerCase() === "vacation_balance")
        ) {
          const action = JSON.parse(response?.data?.actionResult);
          const actionMsg = action?.msg;
          if (actionMsg?.includes("Has Not Been Released")) {
            context.pop();
            context.push({
              sender: "assistant",
              message: actionMsg,
            });
          }
          if (actionMsg?.includes("No Records Found")) {
            context.pop();
            context.push({
              sender: "assistant",
              message: actionMsg,
            });
          }
        }

        setMessages([...response.data.context]);
        if (response?.data?.actionResult) {
          setRequestPayload({
            actionResponse: response?.data?.actionResponse,
            actionResult: response?.data?.actionResult,
            actionCode: response?.data?.actionCode,
          });
          setActionParsedObject(JSON.parse(response?.data?.actionResult));
        }

        if (!!response?.data?.tts) {
          const audio = new Audio(response?.data?.tts);
          audio.play();
          setTTSResult(response?.data?.tts);
        }

        setTyping(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setTyping(false);
      });
  };

  const handleSubmitFeedback = () => {
    if (!feedback?.message || !feedback?.rate) {
      setFeedbackError(true);
      return;
    }
    const payload = {
      ...feedback,
      incidentData: JSON.stringify({actions, messages}),
    };
    setFeedbackError(false);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_NEW_URL}/api/v1/error/report`, payload, {
        headers: {
          "Content-Type": "application/json",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          "X-API-KEY":
            "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
          "X-COMPANY-CODE": "UNIHANCE",
        },
      })
      .then((response) => {
        setFeedbackresponse("Thank you for your feedback!");
        setFeedback(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setFeedbackresponse(error?.response?.data || "Sorry an error occurred while trying to submit your feedback");
        setLoading(false);
        setTyping(false);
      });
  };

  useEffect(() => {
    if (requestPayload) {
      handleAction(requestPayload);
    }
  }, [requestPayload]);

  const handleAction = (payload) => {
    try {
      const parsedData = JSON.parse(payload?.actionResult || "");
      console.log("action", {parsedData});
      if (parsedData?.RequireDelegate) {
        MenaITechService.getDelegateTo(parsedData?.From_Date, parsedData?.To_Date)
          .then((response) => {
            setDelegateToList(response.data);
            setShowDelegateToSelection(true);
          })
          .catch((error) => {
            console.log("Get delegate to error", {error});
          });
      } else if (parsedData?.RequireFile) {
        setShowDelegateToSelection(true);
      }
    } catch (error) {}
  };

  const uploadFile = (file) => {
    setUploadingFile(true);
    supportingDocRef.current.value = null;
    MenaITechService.uploadFile(file, file.name)
      .then((response) => {
        setUploadedFile(file);
        setUploadedFileUrl(response.data);
        setUploadingFile(false);
      })
      .catch((error) => {
        setUploadingFile(false);
        console.log("Get delegate to error", {error});
      });
  };

  const handleLogin = () => {
    if (!formData?.companyNumber || !formData?.branchNumber || !formData?.employeeCode || !formData?.password) {
      setLoginError(true);
      return;
    }
    setLoginError(false);
    setLoginLoading(true);
    MenaITechService.login(formData)
      .then((response) => {
        toast("You have logged in successfully!");
        localStorage.setItem("MENAITECH_AUTH", JSON.stringify(response?.data?.data));
        initChat();
        setLoginLoading(false);
        //console.log("Login Response", {response?.data});
      })
      .catch((error) => {
        setLoginLoading(false);
        console.log("Login Error", {error});
      });
  };

  const RenderActionResult = ({result, code}) => {
    let obj;
    let parsedData;
    try {
      //console.log("result", {result});
      // Parse the JSON string
      obj = JSON.parse(result);
      parsedData = obj.data;
    } catch (error) {
      console.log("error", {error});
      return <div className="w-100 text-center">Error parsing JSON data</div>;
    }

    switch (code) {
      case "vacation_balance":
        return <VacationDataView jsonData={parsedData} />;
      case "salary_slip":
        return <SalaryDataView jsonData={parsedData} />;
      default:
        return "";
    }
  };

  const VacationDataView = ({jsonData}) => {
    let parsedData = jsonData;
    if (!parsedData || !parsedData.length) {
      return "";
    }
    return (
      <div className="position-relative p-2 border rounded mt-2 ">
        <Button
          onClick={() => {
            setActionParsedObject(null);
            setRequestPayload(null);
          }}
          variant="light"
          style={{position: "absolute", top: "10px", right: "10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="11"
            viewBox="0 0 352 512">
            <path d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z" />
          </svg>
        </Button>
        <h2>Vacation Data</h2>
        {parsedData && parsedData.length > 0 ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Vacation Description</th>
                <th>Year</th>
                <th>Up to End of Year Balance</th>
                <th>Previous Balance</th>
                <th>Remaining Previous</th>
                <th>Vacation Days</th>
                <th>New Balance</th>
                <th>Up to Date Balance</th>
                <th>Vacation Type</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr key={index}>
                  <td>{item.vacation_desc}</td>
                  <td>{item.year}</td>
                  <td>{item.up_to_end_of_year_balance}</td>
                  <td>{item.Previous_Balance}</td>
                  <td>{item.Remaining_Previous}</td>
                  <td>{item.Vacation_Days}</td>
                  <td>{item.newBalance}</td>
                  <td>{item.up_to_date_balance}</td>
                  <td>{item.Vacation_type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No data available</div>
        )}
      </div>
    );
  };

  const SalaryDataView = ({jsonData}) => {
    let data = jsonData;
    if (!data || !data.month_name) {
      return "";
    }
    const renderTable = (items, title) => {
      if (!items || !items?.length) {
        return "";
      }
      return (
        <>
          <h3>{title}</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Description</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.description}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    };

    return (
      <div className="w-100 position-relative p-2 border rounded mt-2 ">
        <Button
          onClick={() => {
            setActionParsedObject(null);
            setRequestPayload(null);
          }}
          variant="light"
          style={{position: "absolute", top: "10px", right: "10px"}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="11"
            viewBox="0 0 352 512">
            <path d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z" />
          </svg>
        </Button>
        <h2>
          Salary Data for {data.month_name} {data.year}
        </h2>
        {renderTable(data.Income, "Income")}
        {renderTable(data.Deduction, "Deductions")}
        {renderTable(data.PF, "Provident Fund")}
        <div>
          <p>
            <strong>Net Salary:</strong> {data.Net_Salary}
          </p>
          <p>
            <strong>Total Income:</strong> {data.Total_Income}
          </p>
          <p>
            <strong>Total Deductions:</strong> {data.Total_Deductions}
          </p>
          <p>
            <strong>Total PF:</strong> {data.Total_PF}
          </p>
        </div>
      </div>
    );
  };

  const formatMessage = (message, delegateTo, supportingDoc) => {
    const Component = () => {
      return (
        <div>
          {!!message && <p className="mb-0">{message}</p>}
          {!!delegateTo && <p className="mb-0 mt-2">Delegate To: {delegateTo}</p>}
          {!!supportingDoc && <p className="mb-0 mt-1">Supporting Document: {supportingDoc}</p>}
        </div>
      );
    };
    return renderToString(<Component />);
  };

  const confirmAction = (data, lastMessages) => {
    setConfirmingAction(true);
    setTyping(true);
    const parsedData = JSON.parse(data.actionResult);

    if (data.actionCode.startsWith("vacation")) {
      let payload = {
        vacation_type: data.actionCode?.split(":")[1],
        from_date: parsedData.From_Date || "",
        to_date: parsedData.To_Date || "",
        resume_Date: parsedData.Resume_Date || "",
        phone: parsedData.Phone || "",
        Support_documents: uploadedFileUrl,
        transactionDate: moment().format("DD/MM/yyyy"),
        reason: parsedData.Reason || "",
        place: parsedData.Place || "",
        delegated_employee: selectedEmployee?.employeeID,
        lang: "1",
        token: MenaITechService.getToken(),
      };

      MenaITechService.requestVacation(payload)
        .then((response) => {
          console.log("vacation request response", {response, lastMessages});

          let msgs = [
            ...lastMessages,
            {
              sender: "assistant",
              message: response.data.success ? "Your leave request was submitted successfully" : `Sorry!, ${response.data.message}`,
            },
          ];
          setMessages(msgs);
          setConfirmingAction(false);
          setTyping(false);
          setSelectedEmployee(null);
          setUploadedFile(null);
          setDelegateToList([]);
          setAdditionalDataError(false);
          setUploadedFileUrl(null);
          if (response?.data?.success) {
            toast("Your vacation request was submitted successfully!");
          }
        })
        .catch((error) => {
          let msgs = [
            ...lastMessages,
            {
              sender: "assistant",
              message: "There was an error processing your vacation request.",
            },
          ];
          setMessages(msgs);
          setConfirmingAction(false);
          setTyping(false);
          toast.error("Vacation request error");
          console.log("Vacation request error", {error});
        });
    } else {
      let payload = {
        leave_type: data.actionCode?.split(":")[1],
        From_Time: parsedData.From_Time,
        To_Time: parsedData.To_Time,
        Support_documents: uploadedFileUrl,
        transactionDate: parsedData.Date,
        reason: parsedData.Reason,
        language: "1",
        token: MenaITechService.getToken(),
      };

      MenaITechService.requestLeave(payload)
        .then((response) => {
          let msgs = [
            ...lastMessages,
            {
              sender: "assistant",
              message: response.data.success ? "Your leave request was submitted successfully" : `Sorry!, ${response.data.message}`,
            },
          ];
          console.log("leave request response", {response, lastMessages});

          setMessages(msgs);
          setConfirmingAction(false);

          setTyping(false);
          setSelectedEmployee(null);
          setUploadedFile(null);
          setDelegateToList([]);
          setAdditionalDataError(false);
          setUploadedFileUrl(null);

          if (response?.data?.success) {
            toast("Your leave request was submitted successfully!");
          }
        })
        .catch((error) => {
          let msgs = [
            ...lastMessages,
            {
              sender: "assistant",
              message: "There was an error processing your leave request.",
            },
          ];

          setMessages(msgs);
          setConfirmingAction(false);
          toast.error("Leave request error");
          setTyping(false);
          console.log("Leave request error", {error});
        });
    }
  };

  // useEffect(() => {
  //   console.log("messages", {messages});
  // }, [messages]);

  return (
    <>
      <div className="mb-3 border rounded p-2">
        <h3>Login </h3>
        <Row className="align-items-end">
          <Col>
            <FormGroup>
              <FormLabel>Company Number</FormLabel>
              <Form.Control
                value={formData?.companyNumber || ""}
                onChange={(e) => setFormData({...formData, companyNumber: e.target.value || ""})}
                name="companyNumber"
                placeholder="Company Number"
                type="text"
                disabled={loginLoading}
                isInvalid={loginError && !formData?.companyNumber}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Branch Number</FormLabel>
              <Form.Control
                value={formData?.branchNumber || ""}
                onChange={(e) => setFormData({...formData, branchNumber: e.target.value || ""})}
                name="branchNumber"
                placeholder="Branch Number"
                type="text"
                disabled={loginLoading}
                isInvalid={loginError && !formData?.branchNumber}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Employee Code</FormLabel>
              <Form.Control
                value={formData?.employeeCode || ""}
                onChange={(e) => setFormData({...formData, employeeCode: e.target.value || ""})}
                name="employeeCode"
                placeholder="Employee Code"
                type="text"
                disabled={loginLoading}
                isInvalid={loginError && !formData?.employeeCode}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Password</FormLabel>
              <Form.Control
                value={formData?.password || ""}
                onChange={(e) => setFormData({...formData, password: e.target.value || ""})}
                name="password"
                placeholder="Password"
                type="text"
                disabled={loginLoading}
                isInvalid={loginError && !formData?.password}
              />
            </FormGroup>
          </Col>
          <Col>
            <Button
              style={{marginLeft: "15px"}}
              disabled={loginLoading || !formData.companyNumber || !formData.employeeCode || !formData.branchNumber || !formData.password}
              onClick={handleLogin}>
              {loginLoading && <Spinner size="sm" />}
              {!loginLoading && "Login"}
            </Button>
          </Col>
        </Row>
      </div>

      <MainContainer
        className="flex-column"
        style={{width: 800, margin: "auto", flexBasis: "90%"}}>
        <h3 className="px-3 py-2 border-bottom w-100 d-flex align-items-center justify-content-between">
          <span>Assistant</span>

          <div>
            <small>
              <Form>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Text to Speech"
                  checked={enableTextToSpeech}
                  onChange={(e) => {
                    localStorage.setItem("TTS_ENABLED", e.target.checked);
                    setEnableTextToSpeech(e.target.checked);
                  }}
                />
              </Form>
            </small>
            {messages?.length > 1 && (
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  initChat();
                  setActionParsedObject(null);
                  setRequestPayload(null);
                }}>
                Clear
              </Button>
            )}
          </div>
        </h3>
        <ChatContainer style={{padding: 8}}>
          <MessageList
            style={{padding: 8}}
            typingIndicator={
              typing ? (
                <TypingIndicator
                  style={{margin: 4, opacity: 0.5}}
                  content="UnihanceBot is typing..."
                />
              ) : null
            }>
            {messages.map((msg, i) => {
              return (
                <Message
                  key={i}
                  model={{
                    ...msg,
                    message: msg.message,
                    direction: msg?.sender === "user" ? "outgoing" : "incoming",
                  }}
                  style={{marginBlock: 8, overflowX: "auto", textAlign: isMainlyArabicWithEnglish(msg.message) ? "right" : "left"}}
                />
              );
            })}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={hdlSend}
            attachButton={false}
            disabled={loading || confirmingAction}
          />
        </ChatContainer>
      </MainContainer>
      <div className="d-flex align-items-center justify-content-center w-100">{!!ttsResult && <AudioPlayer base64Audio={ttsResult} />}</div>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="webm"
          disabled={loading || confirmingAction}
        />
      </div>
      {!!requestPayload?.actionResponse && !!requestPayload?.actionResult && (
        <div className="">
          <RenderActionResult
            result={requestPayload?.actionResult}
            code={requestPayload?.actionCode}
          />
        </div>
      )}
      <div className="feedback-container py-2 border rounded">
        Please rate your experience
        <div className="feedback-header">
          {Array(5)
            .fill(0)
            .map((n, i) => {
              return (
                <span
                  className={`${feedback?.rate === i + 1 ? "feedback-item selected" : "feedback-item"}`}
                  key={i}
                  style={{borderColor: feedbackError && !feedback?.rate ? "red" : ""}}
                  onClick={() => {
                    setFeedbackresponse("");
                    setFeedback({...feedback, rate: i + 1});
                  }}>
                  {i + 1}
                </span>
              );
            })}
        </div>
        <div className="feedback-body">
          <Form>
            <Form.Control
              value={feedback?.message || ""}
              onChange={(e) => {
                setFeedbackresponse("");
                setFeedback({...feedback, message: e.target.value});
              }}
              placeholder="Your feedback notes"
              as="textarea"
              isInvalid={feedbackError && !feedback?.message}
            />
          </Form>
        </div>
        <div className="feedback-footer py-2">
          <p>{feedbackresponse || ""}</p>
          <Button onClick={handleSubmitFeedback}>Submit</Button>
        </div>
      </div>

      <Modal show={showDelegateToSelection}>
        <ModalHeader>Additional Information Required</ModalHeader>
        <ModalBody>
          {!!actionParsedObject?.RequireDelegate && (
            <FormGroup>
              <FormLabel className={additionalDataError && !selectedEmployee ? "text-danger" : ""}>
                Delegate To <span className="text-danger">*</span>
              </FormLabel>
              <ReactSelect
                options={delegateToList || []}
                value={selectedEmployee || null}
                onChange={(option) => setSelectedEmployee(option)}
                name="selectedEmployee"
                placeholder="Delegate To"
                isDisabled={loginLoading}
                getOptionValue={(o) => o?.employeeID}
                getOptionLabel={(o) => o?.employeeName}
              />
            </FormGroup>
          )}
          {!!actionParsedObject?.RequireFile && (
            <FormGroup className="mt-3">
              <FormLabel className={additionalDataError && !uploadedFile ? "text-danger" : ""}>
                Supporting Document <span className="text-danger">*</span>
              </FormLabel>
              <div className="w-100">
                <Button
                  disabled={uploadingFile}
                  onClick={() => {
                    supportingDocRef?.current && supportingDocRef?.current?.click();
                  }}>
                  {uploadingFile && <Spinner size="sm" />}
                  {!uploadingFile && "Upload"}
                </Button>
                <span className="mx-2">{!!uploadedFile && uploadedFile?.name}</span>
              </div>
              <input
                accept=".pdf,.png,.jpg,.jpeg,.xlsx,.docx,.doc,.xls,.webm"
                type="file"
                className="d-none"
                ref={supportingDocRef}
                onChange={(e) => {
                  if (e.target?.files?.length) {
                    uploadFile(e.target.files[0]);
                  }
                }}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            disabled={uploadingFile}
            onClick={() => {
              if ((actionParsedObject?.RequireFile && !uploadedFile) || (actionParsedObject?.RequireDelegate && !selectedEmployee)) {
                setAdditionalDataError(true);
                return;
              }

              let msgs = [
                ...messages,
                {
                  sender: "user",
                  message: formatMessage("", selectedEmployee?.employeeName, uploadedFile.name),
                },
              ];
              setMessages(msgs);
              confirmAction(requestPayload, msgs);
              setShowDelegateToSelection(false);
            }}>
            Confirm and Submit
          </Button>
          <Button
            variant="secondary"
            disabled={uploadingFile}
            onClick={() => {
              setUploadedFile(null);
              setUploadedFileUrl(null);
              setSelectedEmployee(null);
              setShowDelegateToSelection(false);
            }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Assistant1106;
