import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_NEW_URL}/api/v1/assistant/`;
export const headers = {
  "Content-Type": "multipart/form-data",
  "Cross-Origin-Embedder-Policy": "require-corp",
  "Cross-Origin-Opener-Policy": "same-origin",
  "X-API-KEY": "gNAkvoUohgDsuxtl23H1nYswpmhB8sBB5KCfNIBbqWhTMJNjHiEH5FnSbQ27UhdUdg8Jm83sh8lW52Tu4sqwOMVutImAcoPbKzqzoF8vcIF0rnL5w1rbrpdgItYMKJ3l",
  "X-COMPANY-CODE": "UNIHANCE",
};
const MenaITechService = {
  getToken: () => {
    try {
      const data = JSON.parse(localStorage.getItem("MENAITECH_AUTH"));
      console.log("data", data);
      const token = data?.token;

      return token;
    } catch (error) {
      return null;
    }
  },
  login: (data) => {
    const loginUrl = `https://nowaf.menaitech.com/menaitechqa/application/hrms/DB/include/API-V3/LoginAPI.php`;
    const formData = new FormData();
    formData.append("me_company_no", data.companyNumber);
    formData.append("me_branch_no", data.branchNumber);
    formData.append("me_employee_code", data.employeeCode);
    formData.append("password", data.password);
    formData.append("deviceType", "IOS");
    formData.append(
      "gcmRegID",
      "PbkwHnoh6oXR5xSkGOPYDMrQUa2ou1jhv43u2CNNj3ZsnVuKCV3iDBb1ThL1yGqeUpzjzOoIywohLQRz1a0slh2dbKXUq+5X0adveWwYzTw+ol3zp5+9REcYnkG+tTY8ObuDh2gE0z97rlD6LUINH9+dsPvX/ojuHJaOAaHtK1o="
    );
    return axios.post(loginUrl, formData);
  },
  getDelegateTo: (fromDate, toDate) => {
    const url = `${baseUrl}GetDelegateTo?fromTime=${fromDate}&toTime=${toDate}&token=${MenaITechService.getToken()}`;
    return axios.get(url, {
      headers,
    });
  },
  getActions: () => {
    const url = `${baseUrl}GetUserActionsTypes?&token=${MenaITechService.getToken()}`;
    return axios.get(url, {
      headers,
    });
  },
  uploadFile: (file, name) => {
    const url = `${baseUrl}UploadFile`;
    var formdata = new FormData();
    formdata.append("file", file, name);
    formdata.append("token", MenaITechService.getToken());
    return axios.post(url, formdata, {
      headers,
    });
  },

  requestVacation: (data) => {
    const url = `${baseUrl}ConfirmVacationRequest`;

    return axios.post(url, data, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });
  },
  requestLeave: (data) => {
    const url = `${baseUrl}ConfirmLeaveRequest`;

    return axios.post(url, data, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });
  },
};

export default MenaITechService;
